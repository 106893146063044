<template>
	<div class="mainWrap mainWrap404">
		<div class="page404">
			<div class="wrp404">
				<div class="centeredWrp">
					<div class="errorNumpers">404</div>
					<div class="title404">{{ $t("404page.title1") }}</div>
					<div class="title404">{{ $t("404page.title2") }}</div>
					<p class="sub">{{ $t("404page.sub") }}</p>
					<router-link
						:to="{
							name: 'home',
							params: {
								locale: $i18n.locale == 'uk' ? null : $i18n.locale,
							},
						}"
						class="toHome"
						>{{ $t("404page.toHome") }}</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.mainWrap {
	padding-left: 192px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	@media (max-width: 1600px) {
		padding-left: 155px;
	}
	@media (max-width: 1365px) {
		padding-left: 0;
	}
}
.mainWrap404 {
	padding-left: 0;
}
.page404 {
	flex-grow: 1;
	display: flex;
	background-color: #f4a700;
	.wrp404 {
		flex-grow: 1;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 53px;
		@media (max-width: 768px) {
			margin: 54px 0 0;
		}
		.centeredWrp {
			display: flex;
			flex-direction: column;
			align-items: center;
			.errorNumpers {
				font-style: normal;
				font-weight: 600;
				font-size: 184px;
				line-height: 100%;
				text-align: center;
				color: #f4a700;
				@media (max-width: 768px) {
					font-size: 120px;
				}
			}
			.title404 {
				font-style: normal;
				font-weight: 600;
				font-size: 30px;
				line-height: 130%;
				text-align: center;
				color: #000000;
				@media (max-width: 768px) {
					font-size: 23px;
				}
			}
			.sub {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 174.5%;
				text-align: center;
				color: #000000;
				max-width: 405px;
				margin: 22px 0;
			}
			.toHome {
				display: block;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 140%;
				text-align: center;
				color: #000000;
				width: 258px;
				background: #f4a700;
				padding: 20px 0;
				text-decoration: none;
				margin: 30px 0 0;
			}
		}
	}
}
</style>